import { createApp } from 'vue';
import Quasar from 'quasar/src/vue-plugin.js';; //  Not chunking and lazy loading quasar significantly reduced overall build size for a small-ish bump to chunk-vendors

import { authConfig } from '@/config/auth-config';
import { Authenticator } from '@over-c/web-client-auth';

const importQuasarOptions = () =>
  import(/* webpackChunkName: 'quasarOptions' */ './quasar-options');
const importI18n = () => import(/* webpackChunkName: 'i18n' */ './i18n');
const importRouter = () => import(/* webpackChunkName: 'router' */ './router');
const importApp = () => import(/* webpackChunkName: 'appComp' */ './App.vue');

function startLoadingImportChunks() {
  importQuasarOptions();
  importRouter();
  importI18n();
  importApp();
}

/**
 * Initializes the Vue app with its main dependencies.
 */
export async function initializeApp(): Promise<void> {
  const [
    { QuasarOptions },
    { router },
    { initializeI18n },
    { default: App },
  ] = await Promise.all([
    importQuasarOptions(),
    importRouter(),
    importI18n(),
    importApp(),
  ]);

  createApp(App)
    .use(Quasar, QuasarOptions)
    .use(router)
    .use(initializeI18n())
    .mount('#app');
}

export async function main(): Promise<void> {
  const authenticator = new Authenticator(authConfig);
  const authCode = Authenticator.checkForAuthCode();

  if (!authCode) return authenticator.goToLogin();

  startLoadingImportChunks();

  try {
    await authenticator.startSession(authCode);
    await initializeApp();
  } catch (err) {
    console.log('Failed to initialize home app:', err);
    await Authenticator.goToLogout();
  }
  return undefined;
}
main();
